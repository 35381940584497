import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  GET_LIVE_AUDIO,
  BAN_HOST_FROM_APP,
  BAN_HOST_FROM_ROOMS,
  GET_BANNED_LIVE_AUDIO,
  SEND_WARNING,
  LIFT_BAN,
  DELETE_BAN,
  GET_BANNED_USERS_FROM_LIVE,
  GET_LIVE_VIDEO,
  GET_BANNED_LIVE_VIDEO,
  BAN_LIVE_AUDIO,
  BAN_LIVE_VIDEO,
  PIN_LIVE,
  UPDATE_BAN,
  GET_BANNED_USERS,
  REMOVE_PIN,
} from "./types";

import { LiveType, BannedType } from "../../constants/live.const";

export const getLive = (type) => (dispatch) => {
  axios
    .get(`/liveUser/getLiveUsersForAdmin${type === LiveType.AUDIO ? '?type=audio' : ''}`)
    .then((res) => {
      if (res.data.status) {
        console.log("response getLive: ", res.data)
        dispatch({ type: type === LiveType.AUDIO ? GET_LIVE_AUDIO : GET_LIVE_VIDEO, payload: res.data.liveUsers });
      } else {
      //  Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const pinLive = (id, priority) => (dispatch) => {
  return axios
    .patch(`/liveUser/setPriority?id=${id}&priority=${priority}`)
    .then((res) => {
      if (res.data.status) {
        console.log("response pinLive: ", res.data);
        dispatch({ type: PIN_LIVE, payload: { data: res.data.data, id: id } });
        Toast("success", "Pin live successful");
        return Promise.resolve(); // ✅ Resolve Promise on success
      } else {
        return Promise.reject(res.data.message); // ❌ Reject if API call fails
      }
    })
    .catch((error) => {
      Toast("error", error.message);
      return Promise.reject(error); // ❌ Reject on error
    });
};




export const getBannedUsers = (type, filter) => (dispatch) => {
  let banType = 1;
  let _type = GET_BANNED_USERS;
  if (type === BannedType.LIVE_AUDIO)  {
    banType = 2;
    _type = GET_BANNED_LIVE_AUDIO;
  }
  else if (type === BannedType.LIVE_VIDEO) {
    banType = 3;
    _type = GET_BANNED_LIVE_VIDEO;
  } else if (type === BannedType.USERS_FROM_LIVE) {
    banType = 4;
    _type = GET_BANNED_USERS_FROM_LIVE;
  }

  console.log("getBannedUsers type: ", banType + " " + type)
  console.log("getBannedUsers filter: ", filter)
  axios
    .get(`/ban?banType=${banType}&filter=${filter}`)
    .then((res) => {
      if (res.data.status) {
        console.log("response banned users: ", res.data)
        dispatch({ type: _type, payload: res.data.data });
      } else {
        // Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
}

export const sendWarning = (id) => (dispatch) => {
  axios
    .post(`/liveUser/sendWarning?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: SEND_WARNING, payload: res.data.status });
      } else {
        Toast("error", res.data.message);
      }
      console.log("sendWarning response: ", res.data)
    })
    .catch((error) => Toast("error", error.message));
};

export const removePin = (id) => (dispatch) => {
  console.log("remove pin id : ", id);

  return axios
    .patch(`/liveUser/removePriority?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: REMOVE_PIN, payload: { data: res.data.data, id: id } });
        return Promise.resolve(); // ✅ Resolve the Promise when successful
      } else {
        Toast("error", res.data.message);
        return Promise.reject(res.data.message); // ❌ Reject if API call fails
      }
    })
    .catch((error) => {
      Toast("error", error.message);
      console.log(error);
      return Promise.reject(error); // ❌ Reject if an error occurs
    });
};



export const banLive = (id, data) => (dispatch) => {
  axios
    .post(`/ban`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: data.banType === "2" ? BAN_LIVE_AUDIO : BAN_LIVE_VIDEO, payload: { data: res.data.liveUser, id: id} });
        Toast("success", "Room banned successfully");
      } else {
        Toast("error", res.data.message);
      }
      console.log("banLive response: ", res.data)
    })
    .catch((error) => {
      Toast("error", error.message);
      console.log("banLive error: ", error)
    });
};

export const liftBan = (id) => (dispatch) => {
  axios
    .patch(`/ban/liftBan?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: LIFT_BAN, payload: { data: res.data.data, id: id} });
        Toast("success", "Ban lifted successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
}

export const deleteBan = (id) => (dispatch) => {
  axios
    .patch(`/ban/deleteBan?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_BAN, payload: { data: res.data.data, id: id} });
        Toast("success", "Ban deleted successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
}

export const banHostFromRooms = (id) => (dispatch) => {
  axios
    .post(`/liveUser/banHost?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BAN_HOST_FROM_ROOMS, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
      console.log("banHost response: ", res.data)
    })
    .catch((error) => Toast("error", error.message));
};

export const banHostFromApp = (userId) => (dispatch) => {
  axios
    .patch(`blockUnblock/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BAN_HOST_FROM_APP, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateBan = (data) => (dispatch) => {
  axios
    .patch(`/ban`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_BAN, payload: res.data.data });
        Toast("success", "Ban updated successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};