import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { pinLive } from "../../store/live/action";
//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { CLOSE_PIN_LIVE_DIALOG } from "../../store/live/types";

const PinLiveDialog = (props) => {
  const { pinLiveDialog, pinLiveDialogData } = useSelector((state) => state.live);
  const [data, setData] = useState(null);
  const [priority, setPriority] = useState(0);
  const [errors, setError] = useState("");

  useEffect(() => {
    setError("");
    setData(null);
  }, [pinLiveDialog]);


  useEffect(() => {
    // debugger
    if (pinLiveDialogData) {
      setData(pinLiveDialogData);
      console.log("pinLive -> pinLiveData", pinLiveDialogData)
    }
  }, [pinLiveDialogData]);

  useEffect(
    () => () => {
      setPriority(0)
      setError("");
    },
    [open]
  );

  const dispatch = useDispatch();

  const closePopup = () => {
    setPriority(0)
    dispatch({ type: CLOSE_PIN_LIVE_DIALOG });
  };


  const handleSubmit = () => {
    if (
      !data?._id || !priority || priority < 1
    ) {
      const errors = {};
      if (priority < 0) errors.priority = "invalid value of priority";

      return setError({ ...errors });
    } else {
      dispatch(pinLive(data?._id, priority));

    }
    closePopup();
  };

  return (
    <>
      <Dialog
        open={pinLiveDialog}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Pin live</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">

                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label className="text-gray mb-2">Pin Priority</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="0"
                        min="0"
                        value={priority}
                        onChange={(e) => {
                          setPriority(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              priority: "Pin Priority is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              priority: "",
                            });
                          }
                        }}
                      />
                      {errors.priority && (
                        <div className="ml-2 mt-1">
                          {errors.priority && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.priority}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>


                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { pinLive })(PinLiveDialog);