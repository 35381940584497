import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import { baseURL } from "../../util/Config";
import {
  updateAdmissionSVGA,
  crateAdmissionSVGA,
} from "../../store/AdmissionCar/action";
import { CLOSE_DIALOGUE_ADMISSION_CAR } from "../../store/AdmissionCar/type";

import SVGA from "svgaplayerweb";

const AdmissionCarDialogue = (props) => {
  const { Dialogue, DialogueData } = useSelector(
    (state) => state.admissionSVGA
  );
  const [validity, setValidity] = useState("");
  const [validityType, setValidityType] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [diamond, setDiamond] = useState("");
  const [name, setName] = useState("");
  const [errors, setError] = useState("");

  const [editImagePath, setEditImagePath] = useState("");
  const [editImageData, setEditImageData] = useState("");
  const [editPreviewImageData, setEditPreviewImageData] = useState("");
  const [editPreviewImagePath, setEditPreviewImagePath] = useState("");

  const [addPreviewImagePath, setAddPreviewImagePath] = useState("");
  const [addPreviewImageData, setAddPreviewImageData] = useState("");
  const [addImageData, setAddImageData] = useState("");
  const [addImagePath, setAddImagePath] = useState("");

  const [isSvga, setIsSvga] = useState(true);
  const [showInStore, setShowInStore] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (DialogueData) {
      setValidity(DialogueData.validity);
      setValidityType(DialogueData.validityType);
      setMongoId(DialogueData._id);
      setDiamond(DialogueData.diamond);
      setName(DialogueData.name);
      setEditImagePath(baseURL + DialogueData.image);
      setEditPreviewImagePath(baseURL + DialogueData.imagePreview);
      setShowInStore(DialogueData.showInStore);

      if (DialogueData?.image?.split(".")?.pop() === "svga") {
        setIsSvga(true);
      }
    }
  }, [DialogueData]);

  useEffect(
    () => () => {
      setValidity("");
      setValidityType("");
      setError("");
      setName("");
      setDiamond("");
      setIsSvga(true);
      setEditImageData(null);
      setEditImagePath(null);
      setEditPreviewImageData(null);
      setEditPreviewImagePath(null);
      setAddImageData(null);
      setAddImagePath(null);
      setAddPreviewImageData(null);
      setAddPreviewImagePath(null);
    },
    [Dialogue]
  );

  // // for .svga file
  useEffect(() => {
    if (isSvga && editImagePath) {
      const svgaElement = document.getElementById("svga");
      if (svgaElement) {

        // Error handling
        try {
          const player = new SVGA.Player(`div[attr="${mongoId}"]`);
          const parser = new SVGA.Parser(`div[attr="${mongoId}"]`);
          parser.load(editImagePath, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        } catch (error) {
          console.error("Error initializing SVGA Player:", error);
        }
      } else {
        console.error("SVGA element not found in the DOM");
      }
    }
  }, [editImagePath, isSvga, mongoId]);


  useEffect(() => {
    if (addImagePath) {
      const svgaElement = document.getElementById("svgaAdd");
      if (svgaElement) {

        // Error handling
        try {
          var player = new SVGA.Player(`div[attr="addInputImage"]`);
          var parser = new SVGA.Parser(`div[attr="addInputImage}"]`);
          parser.load(addImagePath, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        } catch (error) {
          console.error("Error initializing SVGA Player:", error);
        }
      } else {
        console.error("SVGA element not found in the DOM");
      }
    }
  }, [addImagePath]);


  const closePopup = () => {
    dispatch({ type: CLOSE_DIALOGUE_ADMISSION_CAR });
  };

  const handleSubmit = () => {
    if (!name || !diamond || diamond < 0 || !validity || validity < 0) {
      const errors = {};
      if (!validity) errors.validity = "Validity is required!";
      if (validity < 0) errors.validity = "invalid value of validity";
      if (!name) errors.name = "Name is Required";
      if (!diamond) errors.diamond = "Diamond is required";
      if (diamond < 0) errors.diamond = "Invalid Diamond ";
      // if (images.length === 0) errors.images = "Please select an Image!";
// TODO: Image validation
      return setError({ ...errors });
    } else {
      const formData = new FormData();
      formData.append("validity", validity);
      formData.append("validityType", validityType ? validityType : "day");
      formData.append("diamond", diamond);
      formData.append("showInStore", showInStore);
      if (DialogueData) {
        formData.append("imageVideo", editImageData);
        formData.append("previewImage", editPreviewImageData)
      } else {
        formData.append("imageVideo", addImageData);
        formData.append("previewImage", addPreviewImageData)
      }
      formData.append("name", name);
      if (DialogueData) {
        props.updateAdmissionSVGA(mongoId, formData);
      } else {
        props.crateAdmissionSVGA(formData);
      }
      closePopup();
    }
  };


  // for preview image
  const handleInputAddImage = (e) => {
    if (e.target.files[0]) {
      setAddImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setAddImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  // for preview image
  const handleInputAddImagePreview = (e) => {
    if (e.target.files[0]) {
      setAddPreviewImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setAddPreviewImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleInputEditImage = (e) => {
    if (e.target.files[0]) {
      setEditImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setEditImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0]?.name?.split(".")?.pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
  };

  const handleInputEditImagePreview = (e) => {
    if (e.target.files[0]) {
      setEditPreviewImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setEditPreviewImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <Dialog
        open={Dialogue}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableScrollLock
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Admission Car</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="text-gray mb-2">Name</label>

                  <input
                    type="text"
                    className="form-control"
                    required=""
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          name: "Name is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          name: "",
                        });
                      }
                    }}
                  />

                  {errors.name && (
                    <div className="ml-2 mt-1">
                      {errors.name && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.name}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>



                <div className="form-group mt-4">
                  <label className="text-gray mb-2">Show in store</label>

                  <Switch
                    checked={showInStore}
                    onChange={(event) => setShowInStore(event.target.checked)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />

                </div>


                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-gray mb-2">Validity</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="1"
                        min="0"
                        value={validity}
                        onChange={(e) => {
                          setValidity(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              validity: "Validity is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              validity: "",
                            });
                          }
                        }}
                      />
                      {errors.validity && (
                        <div className="ml-2 mt-1">
                          {errors.validity && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.validity}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-gray mb-2">Validity Type</label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={validityType}
                        onChange={(e) => {
                          setValidityType(e.target.value);
                        }}
                      >
                        <option value="day" >
                          Day
                        </option>
                        <option value="Month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Diamond</label>
                  <input
                    type="number"
                    className="form-control"
                    required=""
                    min="0"
                    placeholder="20"
                    value={diamond}
                    onChange={(e) => {
                      setDiamond(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          diamond: "diamond is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          diamond: "",
                        });
                      }
                    }}
                  />
                  {errors.diamond && (
                    <div className="ml-2 mt-1">
                      {errors.diamond && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.diamond}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {DialogueData ? (

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="form-group mt-4">
                      <label className="mb-2 text-gray">Select SVGA</label>
                      <input
                        type="file"
                        accept=".svga"
                        className="form-control form-control-sm"
                        required=""
                        onChange={handleInputEditImage}
                      />
                      {editImagePath && (
                        <>
                          <div
                            id="svga"
                            attr={mongoId}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              marginTop: 10,
                              float: "left",
                              objectFit: "contain",
                              marginRight: 15,
                              height: "100px",
                              width: "100px",
                            }}
                          ></div>
                        </>
                      )}
                    </div>

                    {/* preview image */}
                    <div className="form-group mt-4">
                      <label className="mb-2 text-gray">Select Preview Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control form-control-sm"
                        required=""
                        onChange={handleInputEditImagePreview}
                      />
                      {editPreviewImagePath && (
                        <>
                          <img
                            src={editPreviewImagePath}
                            className="mt-3 rounded float-left mb-2"
                            height="100px"
                            width="100px"
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="form-group mt-4" style={{ display: 'flex', flexDirection: 'column' }}>
                      <label className="mb-2 text-gray">Select SVGA</label>
                      <input
                        type="file"
                        accept=".svga"
                        className="form-control form-control-sm"
                        required=""
                        onChange={handleInputAddImage}
                      />
                      {addImagePath && (
                        <>
                          <div
                            id="svgaAdd"
                            attr="addInputImage"
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              marginTop: 10,
                              float: "left",
                              objectFit: "contain",
                              marginRight: 15,
                              height: "100px",
                              width: "100px",
                            }}
                          ></div>
                        </>
                      )}
                    </div>

                    {/* preview image */}
                    <div className="form-group mt-4">
                      <label className="mb-2 text-gray">Select Preview Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control form-control-sm"
                        required=""
                        onChange={handleInputAddImagePreview}
                      />
                      {addPreviewImagePath && (
                        <>
                          <img
                            src={addPreviewImagePath}
                            className="mt-3 rounded float-left mb-2"
                            height="100px"
                            width="100px"
                          />
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className={editImagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { crateAdmissionSVGA, updateAdmissionSVGA })(
  AdmissionCarDialogue
);
