import React from 'react';

import { TablePagination, IconButton } from '@material-ui/core';
import { Refresh, Warning, Block, PinDrop } from '@material-ui/icons';
import { red, yellow, green } from '@material-ui/core/colors';
const TablePaginationActions = React.lazy(() => import("./TablePagination"));
const OnlineLiveTable = ({
  data,
  handleSearch,
  handleOpenBanDialog,
  sendWarning,
  pinLive,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  page,
  getPartyRooms,
}) => {
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header pb-0">
            <div className="row my-3">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                <IconButton color="primary" aria-label="room control" onClick={getPartyRooms} component="span">
                  <Refresh />
                </IconButton>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                <form action="">
                  <div className="input-group mb-3 border rounded-pill">
                    <div className="input-group-prepend border-0">
                      <div id="button-addon4" className="btn text-danger">
                        <i className="fas fa-search mt-2"></i>
                      </div>
                    </div>
                    <input
                      type="search"
                      placeholder="What're you searching for?"
                      aria-describedby="button-addon4"
                      className="form-control bg-none border-0 rounded-pill searchBar"
                      onChange={handleSearch}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="card-body card-overflow">
            <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>

            <table className="table" style={{ borderCollapse: "collapse" }}>
              <thead className="text-center">
                <tr>
                  <th>No</th>
                  <th>Priority #</th>
                  <th>VibeLynk ID</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Viewers</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {data.length > 0 ? (
                  (rowsPerPage > 0
                    ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : data
                  ).map((data, index) => {
                    return (
                      <>
                        <tr
                        >
                          <td>{index + 1}</td>
                          <td>{data?.priority < 9999 ? data?.priority : '-'}</td>
                          <td>{data?.liveUserUniqueId || ""}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              className="mx-auto"
                              src={data.image}
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              onerror='this.src=,`${baseURL}storage/male.png`"'
                            />
                          </td>
                          <td>{data?.roomName || ""}</td>
                          <td className="text-danger">{data?.viewers || 0}</td>
                          <td className="pointer-cursor">
                            <IconButton aria-label="room control" onClick={() => pinLive(data)} component="span">
                              <PinDrop style={{ color: data?.priority >= 9999 ? green[500] : red[500] }} />
                            </IconButton>
                            <IconButton aria-label="room control" onClick={() => sendWarning(data)} component="span">
                              <Warning style={{ color: yellow[500] }} />
                            </IconButton>
                            <IconButton color="primary" aria-label="room control" onClick={() => handleOpenBanDialog(data)} component="span">
                              <Block style={{ color: red[500] }} />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7" align="center">
                      Nothing to show!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <TablePagination
              id="pagination"
              component="div"
              rowsPerPageOptions={[
                5,
                10,
                25,
                100,
                { label: "All", value: -1 },
              ]}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineLiveTable;