import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { banHostFromApp, banHostFromRooms, banLive } from "../../store/live/action";
//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import { Cancel } from "@material-ui/icons";
import { CLOSE_ADD_BAN_DIALOG, CLOSE_EDIT_BAN_DIALOG } from "../../store/live/types";
import { BannedType } from "../../constants/live.const";
import { add } from "date-fns";

const AddBanDialog = (props) => {
  const { addBanDialog, addBanDialogData } = useSelector((state) => state.live);
  const [data, setData] = useState(null);
  const [duration, setDuration] = useState(1);
  const [durationUnit, setDurationUnit] = useState("");
  const [banDurationDisplay, setBanDurationDisplay] = useState("");
  const [banType, setBanType] = useState(1);
  const [banReason, setBanReason] = useState("Banned by Admin");
  const [errors, setError] = useState("");
  const [banMode, setBanMode] = useState(0);

  useEffect(() => {
    setError("");
    setData(null);
  }, [addBanDialog]);


  useEffect(() => {
    // debugger
    if (addBanDialogData) {
      setDuration(addBanDialogData?.duration);
      setDurationUnit(addBanDialogData?.durationUnit);
      setData(addBanDialogData);
      getBanduration(addBanDialogData?.banCount);
      console.log("BanDialog -> addBanDialogData", addBanDialogData)
    }
  }, [addBanDialogData]);

  useEffect(
    () => () => {
      setDuration(1)
      setDurationUnit("")
      setError("");
      setBanMode(0);
    },
    [open]
  );

  const dispatch = useDispatch();

  const closePopup = () => {
    setBanMode(0)
    dispatch({ type: CLOSE_ADD_BAN_DIALOG });
  };

  const handleChangeBanMode = () => {
    setBanMode(1);
  };


  const getBanduration = (banCount) => {
    if (banCount === 0) {
      setBanDurationDisplay("1 day");
    } else if (banCount === 1) {
      setBanDurationDisplay("3 day");
    } else if (banCount === 2) {
      setBanDurationDisplay("7 day");
    } else if (banCount === 3) {
      setBanDurationDisplay("30 day");
    } else if (banCount === 4) {
      setBanDurationDisplay("365 day");
    } else if (banCount === 5) {
      setBanDurationDisplay("10000 day");
    } else {
      setBanDurationDisplay("20000 day");
    }
  }

  const handleSubmit = () => {
    if (
      !data?._id || (!duration && banMode === 1) || duration <= 0
    ) {
      const errors = {};
      if (duration < 0) errors.duration = "invalid value of duration";
      if (!duration && banMode === 1) errors.duration = "Validity is required!";

      return setError({ ...errors });
    } else {
      let payloadBanType = "1";

      if (banType == 1) {
        payloadBanType =  props.banType === BannedType.LIVE_AUDIO ? "2" : "3"
      } else if (banType == 2) {
        payloadBanType = "4"
      } else {
        payloadBanType = "1"
      }
      const payload = {
        reason: banReason,
        user: data.liveUserId,
        liveStreamingId: data.liveStreamingId,
        banType: payloadBanType,
        duration: duration ? duration.toString() : null,
        banMode: banMode,
        durationUnit: durationUnit ? durationUnit : "day",
      };

      console.log("banType", banType)
      console.log("payload", payload)
      dispatch(banLive(data._id, payload));
    }
    closePopup();
  };

  return (
    <>
      <Dialog
        open={addBanDialog}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">{ props.banType === BannedType.LIVE_AUDIO ? 'Ban Party Room' : 'Ban Live' }</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="form-group row">
                    <label className="text-gray mb-2">Room Name</label>
                    <span className="font-weight-bold h4 text-white">{data?.roomName || ""}</span>
                  </div>

                  <div className="form-group mt-2 row">
                    <label className="text-gray mb-2">Room Owner</label>
                    <span className="font-weight-bold h4 text-white">{data?.name || ""}</span>
                  </div>

                  <div className="form-group mt-4">
                    <label className="text-gray mb-2">Ban Reason (Optional)</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Banned by Admin"
                      value={banReason}
                      onChange={(e) => {
                        setBanReason(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label className="text-gray mb-2">Ban Type</label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={durationUnit}
                        onChange={(e) => {
                          setBanType(e.target.value);
                        }}
                      >
                        <option value = "1">
                          { props.banType === BannedType.LIVE_AUDIO ? "Ban Party Room" : "Ban Live" }
                        </option>
                        <option value="2">Ban Host from Live</option>
                        <option value="3">Ban host from App</option>
                      </select>
                    </div>
                  </div>


                  <div className="form-group mt-4 flex" >
                    <label className="text-gray mb-2 mr-2">Previous offense count: </label>
                    <span className="font-weight-bold h4 text-white">{data?.banCount || 0}</span>
                  </div>
                  <div className="form-group mt-2" style={{ display: banMode === 0 ? "block" : "none"}}>
                    <label className="text-gray mb-2">Ban duration: </label>
                    <span className="font-weight-bold h4 text-white">{`${banDurationDisplay}`}</span>
                    <IconButton color="primary" aria-label="room control" onClick={() => handleChangeBanMode()} component="span">
                      {banMode === 0 ? <Edit /> : <Close />}
                    </IconButton>
                  </div>

                  <div className="col-md-6 mt-3" style={{ display: banMode === 0 ? "none" : "block" }}>
                    <div className="form-group">
                      <label className="text-gray mb-2">Duration</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="1"
                        min="0"
                        value={duration}
                        onChange={(e) => {
                          setDuration(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              duration: "Duration is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              duration: "",
                            });
                          }
                        }}
                      />
                      {errors.duration && (
                        <div className="ml-2 mt-1">
                          {errors.duration && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.duration}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3" style={{ display: banMode === 0 ? "none" : "block" }}>
                    <div className="form-group">
                      <label className="text-gray mb-2">Duration Type</label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={durationUnit}
                        onChange={(e) => {
                          setDurationUnit(e.target.value);
                        }}
                      >
                        <option value="day" >
                          day
                        </option>
                        <option value="month">month</option>
                        <option value="year">year</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { banHostFromApp, banLive, banHostFromRooms })(AddBanDialog);
