export const GET_LIVE_AUDIO = "GET_LIVE_AUDIO";
export const GET_BANNED_LIVE_AUDIO = "GET_BANNED_LIVE_AUDIO";

export const GET_BANNED_USERS = "GET_BANNED_USERS";

export const GET_BANNED_USERS_FROM_LIVE = "GET_BANNED_USERS_FROM_LIVE";

export const GET_LIVE_VIDEO = "GET_LIVE_VIDEO";
export const GET_BANNED_LIVE_VIDEO = "GET_BANNED_LIVE_VIDEO";

export const SEND_WARNING = "SEND_WARNING";

export const BAN_HOST_FROM_APP = "BAN_HOST_FROM_APP";
export const BAN_HOST_FROM_ROOMS = "BAN_HOST_FROM_ROOMS";
export const BAN_LIVE_AUDIO = "BAN_LIVE_AUDIO";
export const BAN_LIVE_VIDEO = "BAN_LIVE_VIDEO";

export const PIN_LIVE = "PIN_LIVE";

export const LIFT_BAN = "LIFT_BAN";
export const DELETE_BAN = "DELETE_BAN";
export const UPDATE_BAN = "UPDATE_BAN";

export const REMOVE_PIN = "REMOVE_PIN";

export const OPEN_ADD_BAN_DIALOG = "OPEN_ADD_BAN_DIALOG";
export const CLOSE_ADD_BAN_DIALOG = "CLOSE_ADD_BAN_DIALOG";

export const OPEN_EDIT_BAN_DIALOG = "OPEN_EDIT_BAN_DIALOG";
export const CLOSE_EDIT_BAN_DIALOG = "CLOSE_EDIT_BAN_DIALOG";

export const OPEN_PIN_LIVE_DIALOG = "OPEN_PIN_LIVE_DIALOG"; 
export const CLOSE_PIN_LIVE_DIALOG = "CLOSE_PIN_LIVE_DIALOG";