export const LiveType = Object.freeze({
  AUDIO: "LIVE_AUDIO",
  VIDEO: "LIVE_VIDEO",
});

export const BannedType = Object.freeze({
  USERS: "BANNED_USERS",
  LIVE_VIDEO: "BANNED_LIVE_VIDEO",
  LIVE_AUDIO: "BANNED_LIVE_AUDIO",
  USERS_FROM_LIVE: "BANNED_USERS_FROM_LIVE",
});