import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getLive,
  liftBan,
  banLive,
  sendWarning,
  removePin,
  banHostFromApp,
  banHostFromRooms,
  getBannedUsers,
  deleteBan
} from "../store/live/action";
import { OPEN_ADD_BAN_DIALOG, OPEN_EDIT_BAN_DIALOG, OPEN_PIN_LIVE_DIALOG } from "../store/live/types";
import { BannedType, LiveType } from "../constants/live.const";

//routing
import { Link } from "react-router-dom";

//MUI
import { AppBar, Tabs, Tab } from "@material-ui/core";
//dayjs
import dayjs from "dayjs";

//sweet alert
//alert
import { warningv2, alert, permissionError } from "../util/Alert";
import TabPanel from "../component/TabPanel";
import AddBanDialog from "../component/dialog/AddBanDialog";
import OnlineLiveTable from "../component/table/OnlineLive";
import BannedUsersTable from "../component/table/BannedUsers";
import EditBanDialog from "../component/dialog/EditBanDialog";
import PinLiveDialog from "../component/dialog/PinLiveDialog";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PartyRoom = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [tabValue, setTabValue] = useState(0);
  const [bannedFilter, setBannedFilter] = useState('active');

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getLive(LiveType.AUDIO); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.getBannedUsers(BannedType.LIVE_AUDIO, bannedFilter); // eslint-disable-next-line
  }, [data, bannedFilter]);


  const liveAudio = useSelector((state) => state.live.liveAudio);
  const bannedPartyRooms = useSelector((state) => state.live.bannedLiveAudio);

  useEffect(() => {
    setData(liveAudio);
    console.log("list of party rooms: ", liveAudio);
  }, [liveAudio]);

  useEffect(() => { // eslint-disable-next-line
    setData2(bannedPartyRooms);
    console.log("list of banned party rooms: ", bannedPartyRooms);
  }, [bannedPartyRooms]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenBanDialog = (data) => {
    dispatch({ type: OPEN_ADD_BAN_DIALOG, payload: data });
  }
  const handleOpenPinDialog = (data) => {
    console.log("handleOpenPinDialog data: ", data);
  
    if (data?.priority >= 9999) {
      dispatch({ type: OPEN_PIN_LIVE_DIALOG, payload: data });
    } else {
      const _data = warningv2("REMOVE PIN", "Are you sure you want to remove pin from this room?");
  
      _data
        .then((aBoolean) => {
          if (aBoolean) {
            if (!hasPermission) return permissionError();
  
            props.removePin(data?._id)
              .then(() => {
                alert("Success!", "Room pin has been removed!", "success");
              })
              .catch((error) => console.error("Failed to remove pin:", error));
          }
        })
        .catch(console.error);
    }
  };
  

  const handleOpenEditBanDialog = (data) => {
    dispatch({ type: OPEN_EDIT_BAN_DIALOG, payload: data });
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleChangeBannedFilter = (event) => {
    setBannedFilter(event.target.value);
  };



  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();

    if (value) {
      const data = liveAudio.filter((data) => {
        return (
          data?.liveUserUniqueId.toString().indexOf(value) > -1 ||
          data?.roomName?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(liveAudio);
    }
  };

  const handleSearchBannedPartyRoom = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();

    if (value) {
      const data2 = bannedPartyRooms.filter((data) => {
        return (
          data?.uniqueId.toString().indexOf(value) > -1 ||
          data?.name?.toUpperCase()?.indexOf(value) > -1
        );
      });
      setData2(data2);
    } else {
      return setData2(bannedPartyRooms);
    }
  };

  const sendWarning = (liveUserData) => {
    console.log("sendWarning data: ", liveUserData);

    const data = warningv2("SEND WARNING", "Are you sure you want to send warning to this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        if (!hasPermission) return permissionError();
        props.sendWarning(liveUserData._id);
        alert("Success!", `Room has been sent a warning!`, "success");
      }
    })
      .catch((error) => console.log(error))
  }

  const liftBan = (id) => {
    const data = warningv2("LIFT BAN", "Are you sure you want to lift the ban from this room?");
    data.then((aBoolean) => {
      if (aBoolean) {
        props.liftBan(id).then(() => {
          alert("Success!", `Ban has been lifted`, "success");
          props.getBannedUsers(BannedType.LIVE_AUDIO, bannedFilter);
        });
      }
    })
      .catch((error) => console.log(error))
  }


  const deleteBan = (id) => {
    const data = warningv2("DELETE ACTIVE BAN", "Are you sure you want to delete the active ban of this room? Deleted ban will not be counted in the number of violations.");
    data.then((aBoolean) => {
      if (aBoolean) {
        props.deleteBan(id);
        alert("Success!", `Ban has been deleted`, "success");
      }
    })
      .catch((error) => console.log(error))
  }

  function calculateDuration(startDate, endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const duration = end.diff(start, 'day');
    return `${duration} days`;
  }

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Party Room</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Party Room
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Party Room Tabs"
          indicatorColor="primary"
        >
          <Tab label="Online Party Room" {...a11yProps(0)} />
          <Tab label="Banned Party Room" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={0}>
        <OnlineLiveTable
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSearch={handleSearch}
          handleOpenBanDialog={handleOpenBanDialog}
          pinLive={handleOpenPinDialog}
          sendWarning={sendWarning}
          getPartyRooms={() => props.getLive(LiveType.AUDIO)}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <BannedUsersTable
          data={data2}
          page={page2}
          rowsPerPage={rowsPerPage2}
          handleChangePage={handleChangePage2}
          handleChangeRowsPerPage={handleChangeRowsPerPage2}
          liftBan={liftBan}
          deleteBan={deleteBan}
          bannedFilter={bannedFilter}
          handleChangeBannedFilter={handleChangeBannedFilter}
          calculateDuration={calculateDuration}
          handleSearch={handleSearchBannedPartyRoom}
          getBannedUsers={() => props.getBannedUsers(BannedType.LIVE_AUDIO, bannedFilter)}
          handleOpenBanDialog={handleOpenEditBanDialog}
        />
      </TabPanel>

      <AddBanDialog banType={BannedType.LIVE_AUDIO} />
      <EditBanDialog />
      <PinLiveDialog />
    </>
  );
};

export default connect(null, {
  getLive,
  liftBan,
  deleteBan,
  sendWarning,
  banLive,
  getBannedUsers,
  banHostFromApp,
  removePin,
  banHostFromRooms
})(PartyRoom);
