import React, { useEffect, useState } from "react";

// antd
import { Popconfirm } from "antd";

// date time formatter util
import { dateTimeFormat } from "../util/dateTimeFormat"
//react-router
import { connect, useSelector } from "react-redux";

import $ from "jquery";
import { Link, useHistory } from "react-router-dom";

import DefaultCoverImage from '../assets/images/profile-back.jpeg'

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

//sweet alert
import { warningv2, permissionError } from "../util/Alert";

//action
import {
  getPost,
  getComment,
  getLike,
  deleteComment,
  updateUser,
  getProfile,
  resetUserImage,
} from "../store/post/action";
import { getFollowersFollowing } from "../store/follower/action";
import { getVideo } from "../store/video/action";
import { editCoin } from "../store/user/action";
import { getUserInventory } from "../store/inventory/action";

//serverPath
import { baseURL } from "../util/Config";

// dayjs
import dayjs from "dayjs";

//image
import Male from "../assets/images/male.png";

//inline edit
import EdiText from "react-editext";

//toast
import { Toast } from "../util/Toast";

import Chip from '@material-ui/core/Chip';

const UserDetail = (props) => {
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const { userProfile } = useSelector((state) => state.post);
  const [userInfoData, setUserInfoData] = useState({});

  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("user"));
  const userFollow = JSON.parse(localStorage.getItem("userFollow"));

  const post = useSelector((state) => state.post.post);
  const video = useSelector((state) => state.video.video);
  const userFrames = useSelector((state) => state.inventory.userFrames);
  const userSvgas = useSelector((state) => state.inventory.userSvgas);

  const followersFollowingList = useSelector(
    (state) => state.followersFollowing.followersFollowing
  );
  const comment = useSelector((state) => state.post.comment);
  const like = useSelector((state) => state.post.like);

  const [userName, setUserName] = useState("");
  const [bio, setBio] = useState("");
  const [name, setName] = useState("");

  // eslint-disable-next-line
  const [showFeed, setShowFeed] = useState(true);
  const [followersFollowing, setFollowersFollowing] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [postVideo, setPostVideo] = useState([]);

  const [coin, setCoin] = useState(0);
  const [isCoin, setIsCoin] = useState(false);

  const [diamond, setDiamond] = useState(0);
  const [isDiamond, setIsDiamond] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [coverImagePath, setCoverImagePath] = useState(null);

  const [frames, setFrames] = useState([]);
  const [svgas, setSvgas] = useState([]);

  useEffect(() => {
    props.getProfile(
      userFollow
        ? userFollow?.fromUserId?._id
          ? userFollow?.fromUserId?._id
          : userFollow?.toUserId?._id
        : user?._id
    );
  }, []);

  useEffect(() => {
    setUserInfoData(userProfile);
    console.log("userProfile", userProfile)
    setImagePath(userProfile?.image);
    setCoverImagePath(userProfile?.coverImage || DefaultCoverImage)
  }, [userProfile]);

  useEffect(() => {
    setBio(userInfoData?.bio);
    setUserName(userInfoData?.username);
    setName(userInfoData?.name);
  }, [userInfoData]);

  $(document).ready(() => {
    $("#manageVideoFeed").on("click", "a", function () {
      // remove className 'active' from all li who already has className 'active'
      $("#manageVideoFeed a.active").removeClass("active");
      // adding className 'active' to current click li
      $(this).addClass("active");
      if ($(this).attr("at") === "Videos") {
        setShowFeed(false);
        setFollowersFollowing(false);
      } else {
        if ($(this).attr("at") === "Feed") {
          setShowFeed(true);
          setFollowersFollowing(false);
        } else if ($(this).attr("at") === "Inventory") {
          setShowFeed(false);
          setFollowersFollowing(false);
          setShowInventory(true);
          setPostVideo([]);
        } else setFollowersFollowing(true);
      }
    });
  });
  // set default Image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", `${baseURL}` + "storage/male.png");
    });
  });

  document.addEventListener(
    "play",
    function (e) {
      var videos = document.getElementsByTagName("video");
      for (var i = 0, len = videos.length; i < len; i++) {
        // eslint-disable-next-line
        if (videos[i] != e.target) {
          videos[i].pause();
        }
      }
    },
    true
  );

  useEffect(() => {
    $("#manageVideoFeed li a").first().addClass("active");
  }, []);

  useEffect(() => {
    props.getPost(
      userFollow
        ? userFollow?.fromUserId?._id
          ? userFollow?.toUserId
          : userFollow?.fromUserId
        : user._id
    ); // eslint-disable-next-line
  }, [user?._id]);

  useEffect(() => {
    props.getUserInventory(user?._id, 'frame');
  }, [user?.id]);

  useEffect(() => {
    props.getUserInventory(user?._id, 'svga');
  }, [user?._id]);

  useEffect(() => {
    setFrames(userFrames);
  }, [userFrames]);

  useEffect(() => {
    setSvgas(userSvgas);
  }, [userSvgas]);

  useEffect(() => {
    setPostVideo(post);
  }, [post]);
  useEffect(() => {
    setPostVideo(video);
  }, [video]);

  const handleInventory = () => {
    // props.getUserInventory(user?._id, 'svga');
    // props.getUserInventory(user?._id, 'frame');
    // console.log("inventory in user details: ", userFrames);
  }

  let now = dayjs();

  const handleLike = (id, index, type) => {
    $(`#commentWrap${index}`).slideUp();
    $(`#likeWrap${index}`).slideToggle("slow");
    props.getLike(id, type);
  };
  const handleComment = (id, index, type) => {
    $(`#likeWrap${index}`).slideUp();
    $(`#commentWrap${index}`).slideToggle("slow");
    props.getComment(id, type);
  };

  const handleFollowersFollowing = (type, id) => {
    props.getFollowersFollowing(type, id);
  };

  const [post_, setPost] = useState("post");

  const handleVideo = (id) => {
    setPost("video");
    props.getVideo(id);
  };

  const handlePost = (id) => {
    setPost("post");
    props.getPost(id);
  };

  function handleDelete(commentId, index) {
    if (!hasPermission) return permissionError();
    const commentCount = $(`#comment${index}`).text();
    parseInt(commentCount) > 0 &&
      $(`#comment${index}`).text(parseInt(commentCount) - 1);
    props.deleteComment(commentId);
  }

  const handleUserInfo = (user, type) => {
    type === "Like"
      ? (localStorage.removeItem("userFollow"),
        localStorage.setItem("user", JSON.stringify(user)))
      : (localStorage.setItem("userFollow", JSON.stringify(user)),
        localStorage.removeItem("user"));

    window.location.href = "/admin/user/detail";
  };

  // const handleSave = (coin, id, type) => {
  //   if (!hasPermission) return permissionError();
  //   const validNumber = /^\d+$/.test(coin);
  //   if (!validNumber) {
  //     return Toast("error", "Invalid Value");
  //   }
  //   let data;
  //   if (type === "rCoin") {
  //     const newObj = {
  //       ...user,
  //       rCoin: coin,
  //     };

  //     localStorage.setItem("user", JSON.stringify(newObj));
  //     setIsCoin(true);
  //     setCoin(coin);
  //     data = {
  //       userId: id,
  //       rCoin: coin,
  //     };
  //   } else {
  //     const newObj = {
  //       ...user,
  //       diamond: coin,
  //     };
  //     localStorage.setItem("user", JSON.stringify(newObj));
  //     setIsDiamond(true);
  //     setDiamond(coin);
  //     data = {
  //       userId: id,
  //       diamond: coin,
  //     };
  //   }
  //   props.editCoin(data);
  // };

  const handleEditImage = () => {
    document.getElementById("profileImage").click();
  };

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      const formData = new FormData();
      formData.append("name", userProfile?.name);
      formData.append("userId", userProfile?._id);
      formData.append("username", userProfile?.username);
      formData.append("image", e.target.files[0]);
      formData.append("bio", userProfile?.bio);
      formData.append("gender", userProfile?.gender);
      formData.append("age", userProfile?.age);

      props.updateUser(formData);
    }
  };

  const [error, setError] = useState({ type: "", message: "" });

  const getRandomString = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < 10; i++) {
      randomString += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }

    return randomString;
  }

  const handleResetUserImage = (value, type) => {
    const userId = value?._id;
    
    const data = warningv2(`Reset ${type}`, `Are you sure you want to reset user's ${type}?`);
    data.then((submit) => {
      if (submit) {
        props.resetUserImage(userId, type);
      }
    }).catch((error) => console.log(error));
  }

  const handleUserUpdate = (value, type) => {
    let updatedName = name;
    let updatedBio = bio;
    let updatedUserName = userName;

    if (type === "name") {
      updatedName = getRandomString();
      setName(updatedUserName);
    } else if (type === "bio") {
      updatedBio = getRandomString();
      setBio(updatedBio);
    }

    const formData = new FormData();
    formData.append("name", updatedName);
    formData.append("userId", value?._id);
    formData.append("username", updatedUserName);
    formData.append("image", imageData);
    formData.append("bio", updatedBio);
    formData.append("gender", value?.gender);
    formData.append("age", value?.age);

    const data = warningv2(`Reset ${type}`, `Are you sure you want to reset user's ${type}?`);
    data.then((submit) => {
      if (submit) {
        props.updateUser(formData);
      }
    }).catch((error) => console.log(error));

  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last"></div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/user" className="text-danger">
                    User
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          {/* <img className="profile-cover" src={coverImagePath} /> */}

          <div className="row">
            <div style={{ position: 'relative', height: 250 }}>
              <img
                src={coverImagePath}
                alt=""
                className="profile-cover"
              />
              <IconButton aria-label="delete"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  marginRight: 10,
                  zIndex: 3
                }}
                color="primary" onClick={
                  () => handleResetUserImage(
                    userFollow
                      ? userFollow?.fromUserId
                      : userFollow?.toUserId
                        ? user
                        : user,
                    "coverImage"
                  )
                }>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <div className="profile-header ">
            <div className="profile-img position-relative">
              {/* <img src={userInfoData?.image} alt="" /> */}

              <div className="row">
                <div className="avatar avatar-xl"
                  onClick={
                    () => handleResetUserImage(
                      userFollow
                        ? userFollow?.fromUserId
                        : userFollow?.toUserId
                          ? user
                          : user,
                      "profileImage"
                    )
                  }
                >
                  <input
                    name="image"
                    id="profileImage"
                    type="file"
                    hidden="hidden"
                    accept="image/*"
                    onChange={handleChangeImage}
                  ></input>
                  <div className="container mt-2">
                    <img
                      src={imagePath}
                      alt=""
                      className="m-auto image mb-2"
                      style={{
                        width: "140px",
                        height: "140px",
                        objectFit: "cover ",
                        borderRadius: "20px",
                      }}
                    />
                    <div className="middle">
                      <i className="fas fa-xmark fa-lg text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="profile-name"
              style={{
                marginTop: "58px",
                marginLeft: "0px",
              }}
            >
              <h4 className="user-profile-name text-white mt-3">
                {/* <EdiText
                  style={{ width: "200px" }}
                  type="text"
                  value={userInfoData?.name}
                  onSave={(val) =>
                    handleUserUpdate(
                      val,
                      userFollow
                        ? userFollow?.fromUserId
                        : userFollow?.toUserId
                          ? user
                          : user,
                      "name"
                    )
                  }
                  className="editClass"
                /> */}
                {userInfoData?.name}
                <IconButton aria-label="delete" color="primary" onClick={
                  () => handleUserUpdate(
                    userFollow
                      ? userFollow?.fromUserId
                      : userFollow?.toUserId
                        ? user
                        : user,
                    "name"
                  )
                }>
                  <DeleteIcon />
                </IconButton>
              </h4>


            </div>
            <div className="profile-header-menu">
              <ul className="list-unstyled" id="manageVideoFeed">
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    at="Feed"
                    onClick={() =>
                      handlePost(
                        userFollow
                          ? userFollow?.fromUserId?._id
                            ? userFollow?.toUserId
                            : userFollow?.fromUserId
                          : user._id
                      )
                    }
                  >
                    Feed ({userInfoData?.post})
                  </a>
                </li>

                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    at="Videos"
                    onClick={() =>
                      handleVideo(
                        userFollow
                          ? userFollow?.fromUserId?._id
                            ? userFollow?.toUserId
                            : userFollow?.fromUserId
                          : user._id
                      )
                    }
                  >
                    Videos ({userInfoData?.video})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    onClick={() =>
                      handleFollowersFollowing(
                        "follower",
                        userFollow
                          ? userFollow?.fromUserId?._id
                            ? userFollow?.toUserId
                            : userFollow?.fromUserId
                          : user._id
                      )
                    }
                  >
                    Followers ({userInfoData?.followers})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    onClick={() =>
                      handleFollowersFollowing(
                        "following",
                        userFollow
                          ? userFollow?.fromUserId?._id
                            ? userFollow?.toUserId
                            : userFollow?.fromUserId
                          : user._id
                      )
                    }
                  >
                    Following ({userInfoData?.following})
                  </a>
                </li>
                <li>
                  <a
                    href={() => false}
                    className="pointer-cursor"
                    at="Inventory"
                    onClick={() =>
                      handleInventory()
                    }
                  >
                    Inventory ({userFrames.length + userSvgas.length})
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">About</h5>

              <ul className="list-unstyled profile-about-list">
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i className="fal fa-user m-r-xxs"></i>
                    Bio &nbsp;
                    {/* <EdiText
                      type="text"
                      value={userInfoData?.bio}
                      onSave={(val) =>
                        handleUserUpdate(
                          val,
                          userFollow
                            ? userFollow?.fromUserId
                            : userFollow?.toUserId
                              ? user
                              : user,
                          "bio"
                        )
                      }
                      className="editClass"
                    /> */}
                    <span className="text-danger">
                      {userInfoData?.bio}
                    </span>
                    <IconButton aria-label="delete" color="primary" onClick={
                      () => handleUserUpdate(
                        userFollow
                          ? userFollow?.fromUserId
                          : userFollow?.toUserId
                            ? user
                            : user,
                        "bio"
                      )
                    }>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                  {error.type == "bio" && (
                    <div className="text-danger">{error?.message}</div>
                  )}
                </li>
                <li>
                  <i className="fa-solid fa-id-card"></i>
                  &nbsp;
                  <span>
                    VibeLynk ID &nbsp;
                    <span className="text-danger">
                      {userInfoData?.uniqueId}
                    </span>
                  </span>
                </li>
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i className="far fa-gem m-r-xxs"></i>
                    Diamond &nbsp;
                    <span className="text-danger">{userInfoData?.diamond}</span>
                  </span>
                </li>
                <li>
                  <span className="d-flex" style={{ alignItems: "baseline" }}>
                    <i className="far fa-registered m-r-xxs "></i>
                    V Coin &nbsp;{" "}
                    <span className="text-danger">{userInfoData?.rCoin}</span>
                  </span>
                </li>
                <li>
                  <i className="fas fa-venus-mars m-r-xxs"></i>
                  <span>
                    Gender &nbsp;{" "}
                    <span className="text-danger">{userInfoData?.gender}</span>
                  </span>
                </li>
                <li>
                  <i className="fas fa-child m-r-xxs"></i>
                  <span>
                    Age &nbsp;
                    <span className="text-danger">{userInfoData?.age}</span>
                  </span>
                </li>

                <li>
                  <i className="fas fa-sign-in-alt m-r-xxs"></i>
                  <span>
                    Login Type &nbsp;
                    <span className="text-danger">
                      {userInfoData?.loginType === 0
                        ? "Google"
                        : userInfoData?.loginType === 1
                          ? "Facebook"
                          : userInfoData?.loginType === 2
                            ? "Quick"
                            : "IOS"}
                    </span>
                  </span>
                </li>
                <li>
                  <i className="fas fa-crown m-r-xxs"></i>
                  <span>
                    isVIP &nbsp;
                    <span
                      className={`${userInfoData?.isVIP ? "text-success" : "text-primary"
                        }`}
                    >
                      {userInfoData?.isVIP ? "Yes" : "No"}
                    </span>
                  </span>
                </li>
                <li>
                  <i className="fas fa-map-marker m-r-xxs"></i>
                  <span>
                    Ip &nbsp;
                    <span className="text-danger">{userInfoData?.ip}</span>
                  </span>
                </li>
                <li>
                  <i className="fas fa-clock m-r-xxs"></i>
                  <span>
                    Last login date &nbsp;
                    <span className="text-danger">
                      {userInfoData?.lastLogin}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Contact Info</h5>
              <ul className="list-unstyled profile-about-list">
                <li>
                  <i className="far fa-envelope m-r-xxs"></i>
                  <span>{userInfoData?.email}</span>
                </li>
                <li>
                  <i className="far fa-compass m-r-xxs"></i>
                  <span>
                    Lives in <span>{userInfoData?.country}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8">
          <div className="card card-bg">
            <div className="card-body ">
              {followersFollowing ? (
                <div
                  className="container followDiv"
                  style={{ maxHeight: 548, overflow: "auto" }}
                >
                  <div className="row post-comments d-flex flex-wrap justify-content-between">
                    {followersFollowingList?.length > 0 ? (
                      followersFollowingList.map((data, index) => {
                        return (
                          <>
                            <div
                              className="col-md-6"
                              style={{
                                paddingRight: "10px",
                                borderRight: `${followersFollowingList.length > 1 &&
                                  index % 2 === 0
                                  ? "1px solid #7d7d83"
                                  : "transparent"
                                  }`,
                              }}
                            >
                              <div className="post-comm post-padding">
                                <img
                                  src={
                                    data?.toUserId?.image
                                      ? data?.toUserId?.image
                                      : data?.fromUserId?.image
                                        ? data?.fromUserId?.image
                                        : Male
                                  }
                                  className="comment-img commentImg"
                                  alt=""
                                />
                                <div
                                  className="comment-container pointer-cursor"
                                  onClick={() => handleUserInfo(data)}
                                >
                                  <span className="comment-author">
                                    {data?.toUserId?.name
                                      ? data?.toUserId?.name
                                      : data?.fromUserId?.name}
                                    <small className="comment-date">
                                      {now.diff(data?.createdAt, "minute") <=
                                        60 &&
                                        now.diff(data?.createdAt, "minute") >= 0
                                        ? now.diff(data?.createdAt, "minute") +
                                        " minutes ago"
                                        : now.diff(data?.createdAt, "hour") >=
                                          24
                                          ? dayjs(data?.createdAt).format(
                                            "DD MMM, YYYY"
                                          )
                                          : now.diff(data?.createdAt, "hour") +
                                          " hour ago"}
                                    </small>
                                  </span>
                                  <span>
                                    {data?.toUserId?.username
                                      ? data?.toUserId?.username
                                      : data?.fromUserId?.username}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p className="text-center">Nothing to Show!!</p>
                    )}
                  </div>
                </div>
              ) : postVideo.length > 0 ? (
                postVideo
                  .slice(0)
                  .reverse()
                  .map((data, index) => {
                    console.log("data", data);

                    return (
                      <>
                        <div className="post">
                          <div className="post-header">
                            <img src={userInfoData?.image} alt="" />
                            <div className="post-info">
                              <span className="post-author">
                                {userInfoData?.name}
                              </span>
                              <br />
                              <span className="post-date">
                                {now.diff(data?.date, "minute") <= 60 &&
                                  now.diff(data?.date, "minute") >= 0
                                  ? now.diff(data?.date, "minute") +
                                  " minutes ago"
                                  : now.diff(data?.date, "hour") >= 24
                                    ? dayjs(data?.date).format("DD MMM, YYYY")
                                    : now.diff(data?.date, "hour") + " hour ago"}
                              </span>
                            </div>
                          </div>
                          {data.isDelete ? (
                            <p className="text-center">
                              This Post is delete by Admin
                            </p>
                          ) : (
                            (console.log("post_", post_),
                              (
                                <>
                                  <div className="post-body">
                                    {post_ == "post" ? (
                                      <img
                                        src={baseURL + data?.post}
                                        className="post-image m-auto py-3"
                                      />
                                    ) : (
                                      <video
                                        src={data?.video}
                                        className="post-image m-auto py-3"
                                        controls
                                        style={{ maxHeight: 400, width: "40%" }}
                                      />
                                    )}
                                  </div>
                                  <div id="myGroup">
                                    <div className="post-actions">
                                      <ul className="list-unstyled">
                                        <li>
                                          <a
                                            href={() => false}
                                            className="like-btn"
                                            onClick={() =>
                                              handleLike(
                                                data?._id,
                                                index,
                                                data.post ? "post" : "video"
                                              )
                                            }
                                            id="likeToggle"
                                          >
                                            {data?.like} Likes
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={() => false}
                                            className="like-btn"
                                            role="button"
                                            onClick={() =>
                                              handleComment(
                                                data?._id,
                                                index,
                                                data.post ? "post" : "video"
                                              )
                                            }
                                            id="commentToggle"
                                          >
                                            <span id={`comment${index}`}>
                                              {data?.comment}
                                            </span>{" "}
                                            &nbsp; Comments
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="post-comments">
                                      <div
                                        className="collapse"
                                        id={`likeWrap${index}`}
                                        style={{
                                          maxHeight: 250,
                                          overflow: "auto",
                                          display: "none",
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-12">
                                            {like?.length > 0 ? (
                                              like.map((like) => {
                                                return (
                                                  <>
                                                    <div className="post-comm post-padding">
                                                      <img
                                                        src={
                                                          like.image
                                                            ? like.image
                                                            : Male
                                                        }
                                                        className="comment-img"
                                                        alt=""
                                                        onClick={() =>
                                                          handleUserInfo(
                                                            like.user,
                                                            "Like"
                                                          )
                                                        }
                                                      />
                                                      <div className="comment-container pointer-cursor">
                                                        <span className="comment-author">
                                                          <span
                                                            onClick={() =>
                                                              handleUserInfo(
                                                                like.user,
                                                                "Like"
                                                              )
                                                            }
                                                          >
                                                            {like.name}
                                                          </span>
                                                          <small className="comment-date">
                                                            {like.time}
                                                          </small>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <p className="text-center">
                                                No data found
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="collapse"
                                        id={`commentWrap${index}`}
                                        style={{
                                          maxHeight: 250,
                                          overflow: "auto",
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-12">
                                            {comment?.length > 0 ? (
                                              comment.map((comment) => {
                                                return (
                                                  <>
                                                    <div className="post-comm post-padding">
                                                      <img
                                                        src={
                                                          comment.image
                                                            ? comment.image
                                                            : Male
                                                        }
                                                        className="comment-img commentImg"
                                                        alt=""
                                                        onClick={() =>
                                                          handleUserInfo(
                                                            comment.user
                                                          )
                                                        }
                                                      />
                                                      <div className="comment-container">
                                                        <span className="comment-author pointer-cursor">
                                                          <span
                                                            onClick={() =>
                                                              handleUserInfo(
                                                                comment.user
                                                              )
                                                            }
                                                          >
                                                            {comment.name}
                                                          </span>
                                                          <small className="comment-date">
                                                            {comment.time}
                                                            <Popconfirm
                                                              title="Are you sure to delete this comment?"
                                                              onConfirm={() =>
                                                                handleDelete(
                                                                  comment._id,
                                                                  index
                                                                )
                                                              }
                                                              okText="Yes"
                                                              cancelText="No"
                                                              placement="top"
                                                            >
                                                              <i className="fas fa-trash-alt text-danger comment-delete pointer-cursor"></i>
                                                            </Popconfirm>
                                                          </small>
                                                        </span>
                                                        <span className="pointer-cursor">
                                                          {comment.comment}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <p className="text-center">
                                                No data found
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))
                          )}
                        </div>
                      </>
                    );
                  })
              ) : showInventory ? (
                <>
                  <h2 style={{ color: '#fff' }}>Frames</h2>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                      {frames.length > 0 ? frames.map((frame, index) => (
                        <div key={index} style={{ marginBottom: 30, display: 'flex', flexDirection: 'row', gap: 10 }}>
                          <img src={`${baseURL}${frame.image}`} style={{ height: 40, width: 40 }} />
                          <div>
                            <div>{frame.name}</div>
                            {!frame.expired && <div>{frame.validationTag}</div>}
                            {frame.expired && (<div><strong>Expired on: </strong>{dateTimeFormat(frame.expiryDate)}</div>)}
                            {frame.activated && (<div><strong>Expires on: </strong>{dateTimeFormat(frame.expiryDate)}</div>)}
                            <div>
                              {
                                frame.expired ?
                                  (<Chip size="small" label="Expired" style={{ background: '#d50000', color: '#fff' }} />)
                                  : frame.isSelected ?
                                    (<Chip size="small" label="Selected" style={{ background: '#2e7d32', color: '#fff' }} />)
                                    : frame.activated ?
                                      (<Chip size="small" label="Used" color="primary" />)
                                      : (<Chip size="small" label="Not used yet" />)
                              }
                            </div>
                          </div>
                        </div>
                      )) : (<p className="text-center">No frames in user inventory</p>)}
                    </div>
                  </div>

                  <h2 style={{ color: '#fff' }}>Entrance Effects</h2>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                      {svgas.length > 0 ? svgas.map((svga, index) => (
                        <div key={index} style={{ marginBottom: 30, display: 'flex', flexDirection: 'row', gap: 10 }}>
                          <img src={`${baseURL}${svga.imagePreview}`} style={{ height: 40, width: 40 }} />
                          <div>
                            <div>{svga.name}</div>
                            <div>{svga.validationTag}</div>
                            {svga.activated && (<div><strong>Expires in: </strong>{dateTimeFormat(svga.expiryDate)}</div>)}
                            <div>
                              {
                                svga.expired ?
                                  (<Chip size="small" label="Expired" style={{ background: '#d50000', color: '#fff' }} />)
                                  : svga.isSelected ?
                                    (<Chip size="small" label="Selected" style={{ background: '#2e7d32', color: '#fff' }} />)
                                    : svga.activated ?
                                      (<Chip size="small" label="Used" color="primary" />)
                                      : (<Chip size="small" label="Not used yet" />)
                              }
                            </div>
                          </div>
                        </div>
                      )) : (<p className="text-center">No entrance effects in user inventory</p>)}
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center">Nothing to show!!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getPost,
  getLike,
  getComment,
  getFollowersFollowing,
  getVideo,
  deleteComment,
  editCoin,
  updateUser,
  getProfile,
  getUserInventory,
  resetUserImage,
})(UserDetail);
