import React from "react";
import { TablePagination, IconButton } from "@material-ui/core";
import { Refresh, Warning, Block } from "@material-ui/icons";
import { red, yellow } from "@material-ui/core/colors";
import dayjs from "dayjs";
const TablePaginationActions = React.lazy(() => import("./TablePagination"));
const BannedUsersTable = ({
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    liftBan,
    deleteBan,
    calculateDuration,
    bannedFilter,
    handleChangeBannedFilter,
    data,
    getBannedUsers,
    handleSearch,
    handleOpenBanDialog,
}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header pb-0">
                        <div className="row my-3 align-items-center">
                            {/* Left Section: Refresh Button and Filter Dropdown */}
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 d-flex align-items-center">
                                {/* Refresh Button */}
                                <IconButton
                                    color="primary"
                                    aria-label="room control"
                                    onClick={getBannedUsers}
                                    component="span"
                                    className="me-3"
                                >
                                    <Refresh />
                                </IconButton>

                                {/* Filter Dropdown */}
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                    <label htmlFor="validityType" className="form-label mb-1">
                                        Filter Data
                                    </label>
                                    <select
                                        value={bannedFilter}
                                        onChange={handleChangeBannedFilter}
                                        className="form-select form-control"
                                        id="validityType"
                                    >
                                        <option value="all">All</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>

                            {/* Right Section: Search Bar */}
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-3 mt-md-0">
                                <form>
                                    <div className="input-group border rounded-pill p-1">
                                        <div className="input-group-prepend border-0">
                                            <button
                                                type="button"
                                                className="btn btn-link text-decoration-none text-danger"
                                                id="button-addon4"
                                            >
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="search"
                                            placeholder="What're you searching for?"
                                            aria-describedby="button-addon4"
                                            className="form-control border-0 bg-transparent rounded-pill"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="card-body card-overflow">
                        <table className="table" style={{ borderCollapse: "collapse" }}>
                            <thead className="text-center">
                                <tr>
                                    <th>No</th>
                                    <th>VibeLynk ID</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Duration</th>
                                    <th>Count</th>
                                    <th>History</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {data?.length > 0 ? (
                                    (rowsPerPage > 0
                                        ? data.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        : data
                                    ).map((_data, index) => {
                                        return (
                                            <>
                                                <tr key={index}
                                                >
                                                    <td>{index + 1}</td>
                                                    <td>{_data?.uniqueId || ""}</td>
                                                    <td>
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            className="mx-auto"
                                                            src={_data?.image}
                                                            style={{
                                                                boxShadow:
                                                                    "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                objectFit: "cover",
                                                                display: "block",
                                                            }}
                                                            onerror='this.src=,`${baseURL}storage/male.png`"'
                                                        />
                                                    </td>
                                                    <td>{_data?.roomname || _data?.name}</td>
                                                    <td className={_data?.activeBan ? "text-success" : ""}>{_data?.activeBan ? "Active" : "Inactive"}</td>
                                                    <td>{_data?.activeBan ? calculateDuration(_data?.activeBan?.banStart, _data?.activeBan?.banEnd) : "-"}</td>
                                                    <td >{_data?.bans?.length || ""}</td>
                                                    <td
                                                        data-toggle="collapse"
                                                        data-target={`#demo${index}`}
                                                        className="accordion-toggle pointer-cursor" id="btnInfo"
                                                    >
                                                        <i className="fas fa-info-circle fa-lg"></i>
                                                    </td>
                                                    <td className="pointer-cursor">
                                                        <div style={{ display: (_data?.activeBan ? "flex" : "none"), flexDirection: "column" }}>
                                                            <span className="text-danger" onClick={() => liftBan(_data?.activeBan?._id || "")}>Lift Ban</span>
                                                            <span className="text-danger mt-2" onClick={() => handleOpenBanDialog(_data)}>Edit Active Ban</span>
                                                            <span className="text-danger-2 mt-2" onClick={() => deleteBan(_data?.activeBan?._id || "")}>Delete Ban</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="8" className="hiddenRow">
                                                        <div
                                                            id={`demo${index}`}
                                                            className="accordian-body collapse"
                                                        >
                                                            <h6 className="text-danger">
                                                                Ban History
                                                            </h6>
                                                            <table className="w-100">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Ban Start</th>
                                                                        <th>Ban End</th>
                                                                        <th>Ban Duration</th>
                                                                        <th>Status</th>
                                                                        <th>Reason</th>
                                                                        <th>Banned By</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody
                                                                    style={{
                                                                        maxHeight: 100,
                                                                        overflowY: "auto",
                                                                    }}
                                                                >
                                                                    {_data?.bans?.length > 0 ? (
                                                                        _data.bans.map((ban, no) => {
                                                                            return (
                                                                                <tr key={no}>
                                                                                    <td>{no + 1}</td>
                                                                                    <td>
                                                                                        {dayjs(ban?.banStart).format(
                                                                                            "DD MMM, YYYY"
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {dayjs(ban?.banEnd).format(
                                                                                            "DD MMM, YYYY"
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {calculateDuration(ban?.banStart, ban?.banEnd)}
                                                                                    </td>
                                                                                    <td className={ban?.isActive ? "text-success" : ""}>
                                                                                        {ban?.isActive
                                                                                            ? "Active"
                                                                                            : "Inactive"}
                                                                                    </td>
                                                                                    <td>
                                                                                        {ban?.reason || ""}
                                                                                    </td>
                                                                                    <td>
                                                                                        {ban?.bannedBy || ""}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="7" align="center">
                                                                                Nothing to show!!
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="7" align="center">
                                            Nothing to show!!
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <TablePagination
                            id="pagination2"
                            component="div"
                            rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
                            count={data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannedUsersTable;