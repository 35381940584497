import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
    deleteBan,
    getBannedUsers,
    liftBan
} from "../store/live/action";

import { OPEN_EDIT_BAN_DIALOG } from "../store/live/types";
import { BannedType } from "../constants/live.const";
//dayjs
import dayjs from "dayjs";
//config
import { baseURL } from "../util/Config";

//routing
import { Link } from "react-router-dom";

//MUI
import { TablePagination, Tooltip } from "@material-ui/core";

import BannedUsersTable from "../component/table/BannedUsers";
//sweet alert
import { alert, warningv2, permissionError } from "../util/Alert";


import { IconButton } from "@material-ui/core";
import { Refresh, Warning, Block } from "@material-ui/icons";
import EditBanDialog from "../component/dialog/EditBanDialog";

const BannedUsersFromApp = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bannedFilter, setBannedFilter] = useState('active');

    const hasPermission = useSelector((state) => state.admin.admin.flag);

    useEffect(() => {
        props.getBannedUsers(BannedType.USERS, bannedFilter); // eslint-disable-next-line
        console.log("banned users from app: ", data)
    }, [bannedFilter]);

    const bannedUsers = useSelector((state) => state.live.bannedUsers);

    useEffect(() => {
        setData(bannedUsers);
        console.log("banned users from app: ", bannedUsers)
    }, [bannedUsers]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeBannedFilter = (event) => {
        setBannedFilter(event.target.value);
    };

    const handleSearch = (e) => {
        const value = e.target.value.trim().toUpperCase();
        if (value) {
            const data = bannedUsers.filter((data) => {
                return data?.URL?.toUpperCase()?.indexOf(value) > -1;
            });
            setData(data);
        } else {
            return setData(bannedUsers);
        }
    };
    function calculateDuration(startDate, endDate) {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const duration = end.diff(start, 'day');
        return `${duration} days`;
    }

    const deleteBan = (id) => {
        const data = warningv2("DELETE ACTIVE BAN", "Are you sure you want to delete the active ban of this user? Deleted ban will not be counted in the number of violations.");
        data.then((aBoolean) => {
            if (aBoolean) {
                props.deleteBan(id);
                alert("Success!", `Ban has been deleted`, "success");
            }
        })
            .catch((error) => console.log(error))
    }

    const handleOpenEditBanDialog = (data) => {
      dispatch({ type: OPEN_EDIT_BAN_DIALOG, payload: data });
    }

    const liftBan = (id) => {
        const data = warningv2("LIFT BAN", "Are you sure you want to lift the ban from this user?");
        data.then((aBoolean) => {
            if (aBoolean) {
                props.liftBan(id);
                alert("Success!", `Ban has been lifted`, "success");
            }
        })
            .catch((error) => console.log(error))
    }



    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-white">Banned Users From App</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Banned Users From App
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <BannedUsersTable
                data={data}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                liftBan={liftBan}
                deleteBan={deleteBan}
                bannedFilter={bannedFilter}
                handleChangeBannedFilter={handleChangeBannedFilter}
                calculateDuration={calculateDuration}
                handleSearch={handleSearch}
                getBannedUsers={() => props.getBannedUsers(BannedType.USERS, bannedFilter)}
                handleOpenBanDialog={handleOpenEditBanDialog}
            />
            <EditBanDialog />
        </>
    );
};

export default connect(null, { getBannedUsers, liftBan, deleteBan })(
    BannedUsersFromApp
);
