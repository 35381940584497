import {
  GET_LIVE_AUDIO,
  GET_LIVE_VIDEO,
  BAN_LIVE_AUDIO,
  BAN_LIVE_VIDEO,
  SEND_WARNING,
  LIFT_BAN,
  GET_BANNED_LIVE_VIDEO,
  GET_BANNED_LIVE_AUDIO,
  GET_BANNED_USERS_FROM_LIVE,
  OPEN_EDIT_BAN_DIALOG,
  CLOSE_EDIT_BAN_DIALOG,
  CLOSE_ADD_BAN_DIALOG,
  OPEN_ADD_BAN_DIALOG,
  GET_BANNED_USERS,
  OPEN_PIN_LIVE_DIALOG,
  CLOSE_PIN_LIVE_DIALOG,
  PIN_LIVE,
  REMOVE_PIN
} from "./types";

const initialState = {
  liveAudio: [],
  liveVideo: [],
  bannedLiveAudio: [],
  bannedLiveVideo: [],
  bannedUsers: [],
  addBanDialog: false,
  addBandialogData: null,
  editBanDialog: false,
  editBanDialogData: null,
  pinLiveDialog: false,
  pinLiveDialogData: null
};

const PartyRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIVE_AUDIO:
      return {
        ...state,
        liveAudio: action.payload,
      };
    case GET_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: action.payload,
      };
    case GET_BANNED_LIVE_AUDIO:
      return {
        ...state,
        bannedLiveAudio: action.payload,
      };
    case GET_BANNED_LIVE_VIDEO:
      return {
        ...state,
        bannedLiveVideo: action.payload,
      };
    case GET_BANNED_USERS_FROM_LIVE:
      return {
        ...state,
        bannedUsers: action.payload,
      };
    case GET_BANNED_USERS:
      return {
        ...state,
        bannedUsers: action.payload,
      };
    case SEND_WARNING:
      return {
        ...state
      }
    case BAN_LIVE_AUDIO:
      return {
        ...state,
        liveAudio: state.liveAudio.filter((live) => live._id !== action.payload.id),
      };
    case BAN_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: state.liveVideo.filter((live) => live._id !== action.payload.id),
      };
    case LIFT_BAN:
      return {
        ...state
      };
    case OPEN_ADD_BAN_DIALOG:
      return {
        ...state,
        addBanDialog: true,
        addBanDialogData: action.payload || null,
      };
    case CLOSE_ADD_BAN_DIALOG:
      return {
        ...state,
        addBanDialog: false,
        addBanDialogData: null,
      };

    case OPEN_EDIT_BAN_DIALOG:
      return {
        ...state,
        editBanDialog: true,
        editBanDialogData: action.payload || null,
      };
    case CLOSE_EDIT_BAN_DIALOG:
      return {
        ...state,
        editBanDialog: false,
        editBanDialogData: null,
      };
    case OPEN_PIN_LIVE_DIALOG:
      return {
        ...state,
        pinLiveDialog: true,
        pinLiveDialogData: action.payload || null,
      };
    case CLOSE_PIN_LIVE_DIALOG:
      return {
        ...state,
        pinLiveDialog: false,
        pinLiveDialogData: null,
      };
    case PIN_LIVE:
      return {
        ...state,
        // liveAudio: state.liveAudio.map((liveAudio) => {
        //   if (liveAudio._id === action.payload.id) return action.payload.data;
        //   else return liveAudio;
        // }),
      };
    case REMOVE_PIN:
      return {
        ...state,
        // liveAudio: state.liveAudio.map((liveAudio) => {
        //   if (liveAudio._id === action.payload.id) return action.payload.data;
        //   else return liveAudio;
        // }),
      }
    default:
      return state;
  }
};

export default PartyRoomReducer;
