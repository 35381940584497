import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { updateBan } from "../../store/live/action";
//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import { Cancel } from "@material-ui/icons";
import { CLOSE_EDIT_BAN_DIALOG } from "../../store/live/types";

const EditBanDialog = (props) => {
  const { editBanDialog, editBanDialogData } = useSelector((state) => state.live);
  const [data, setData] = useState(null);
  const [duration, setDuration] = useState(1);
  const [durationUnit, setDurationUnit] = useState("");
  const [banDurationDisplay, setBanDurationDisplay] = useState("");
  const [banReason, setBanReason] = useState("Banned by Admin");
  const [errors, setError] = useState("");
  const [banMode, setBanMode] = useState(0);

  useEffect(() => {
    setError("");
    setData(null);
  }, [editBanDialog]);


  useEffect(() => {
    // debugger
    if (editBanDialogData) {
      setDuration(editBanDialogData?.duration);
      setDurationUnit(editBanDialogData?.durationUnit);
      setData(editBanDialogData);
      setBanReason(editBanDialogData?.activeBan?.reason);
      getBandurationFromRange(editBanDialogData?.activeBan?.banStart, editBanDialogData?.activeBan?.banEnd);
      console.log("BanDialog -> editBanDialogData", editBanDialogData)
    }
  }, [editBanDialogData]);

  useEffect(
    () => () => {
      setDuration(1)
      setDurationUnit("")
      setError("");
      setBanMode(0);
    },
    [open]
  );

  const dispatch = useDispatch();

  const closePopup = () => {
    setBanMode(0)
    dispatch({ type: CLOSE_EDIT_BAN_DIALOG });
  };

  const handleChangeBanMode = () => {
    setBanMode(1);
  };

  const getBandurationFromRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = Math.abs(end - start);
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    setBanDurationDisplay(`${days} day${days > 1 ? "s" : ""}`);
    setDuration(days);
    setDurationUnit("day");
  };

  const handleSubmit = () => {
    if (
      !data?._id || (!duration && banMode === 1) || duration <= 0
    ) {
      const errors = {};
      if (duration < 0) errors.duration = "invalid value of duration";
      if (!duration && banMode === 1) errors.duration = "Validity is required!";

      return setError({ ...errors });
    } else {
      const payload = {
        reason: banReason,
        id: data?.activeBan?._id,
        duration: duration ? duration.toString() : null,
        durationUnit: durationUnit ? durationUnit : "day",
      };
      // if (banType === 1) {
      //   dispatch(banLive(data._id, payload));
      // } else if (banType === 2) {
      //   dispatch(banHostFromRooms(data.liveUserId));
      // } else if (banType === 3) {
      //   dispatch(banHostFromApp(data.liveUserId));
      // }
      dispatch(updateBan(payload));

    }
    closePopup();
  };

  return (
    <>
      <Dialog
        open={editBanDialog}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Update Ban Details</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="form-group mt-4">
                    <label className="text-gray mb-2">Ban Reason (Optional)</label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Banned by Admin"
                      value={banReason}
                      onChange={(e) => {
                        setBanReason(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group mt-2" style={{ display: banMode === 0 ? "block" : "none"}}>
                    <label className="text-gray mb-2">Ban duration: </label>
                    <span className="font-weight-bold h4 text-white">{`${banDurationDisplay}`}</span>
                    <IconButton color="primary" aria-label="room control" onClick={() => handleChangeBanMode()} component="span">
                      {banMode === 0 ? <Edit /> : <Close />}
                    </IconButton>
                  </div>

                  <div className="col-md-6 mt-3" style={{ display: banMode === 0 ? "none" : "block" }}>
                    <div className="form-group">
                      <label className="text-gray mb-2">Duration</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="1"
                        min="0"
                        value={duration}
                        onChange={(e) => {
                          setDuration(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              duration: "Duration is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              duration: "",
                            });
                          }
                        }}
                      />
                      {errors.duration && (
                        <div className="ml-2 mt-1">
                          {errors.duration && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.duration}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3" style={{ display: banMode === 0 ? "none" : "block" }}>
                    <div className="form-group">
                      <label className="text-gray mb-2">Duration Type</label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={durationUnit}
                        onChange={(e) => {
                          setDurationUnit(e.target.value);
                        }}
                      >
                        <option value="day" >
                          day
                        </option>
                        <option value="month">month</option>
                        <option value="year">year</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { updateBan })(EditBanDialog);